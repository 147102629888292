import { render, staticRenderFns } from "./CreateForm.vue?vue&type=template&id=3306d221&"
import script from "./CreateForm.vue?vue&type=script&lang=js&"
export * from "./CreateForm.vue?vue&type=script&lang=js&"
import style0 from "./CreateForm.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/RevolutionCloud_ra-web-admin_pF1p/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3306d221')) {
      api.createRecord('3306d221', component.options)
    } else {
      api.reload('3306d221', component.options)
    }
    module.hot.accept("./CreateForm.vue?vue&type=template&id=3306d221&", function () {
      api.rerender('3306d221', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/jt808/deviceBind/modules/CreateForm.vue"
export default component.exports