<template>
  <vxe-modal
    v-model="deviceBindVisible"
    width="35%"
    height="auto"
    :title="title"
    @close="handleCloseDeviceBind"
    show-zoom
    resize
    :lock-view="false"
    :mask="false"
    :esc-closable="true"
    :position="{ top: '10%', left: '30%' }"
    remember
    transfer
    ref="manyVehicleMonitorRef"
  >
    <div class="device-bind-container">
      <!-- <h4>设备绑定</h4> -->
      <a-form-model ref="form" :model="form" :rules="rules">
        <!-- <a-form-model-item label="设备类型" prop="deviceType">
          <a-select placeholder="请选择设备类型" v-model="deviceType" @change="handleDeviceTypeChange">
            <a-select-option :value="1"> GPS车机终端 </a-select-option>
            <a-select-option :value="2"> GPS工牌 </a-select-option>
          </a-select>
        </a-form-model-item> -->

        <a-form-model-item label="设备编号" prop="deviceId" v-if="deviceType !== 3">
          <a-select
            show-search
            allowClear
            placeholder="请输入设备编号"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleDeviceSearch"
            @change="handleDeviceChange"
            :loading="loading"
            v-model="form.deviceId"
          >
            <a-select-option
              v-for="d in deviceArray"
              :key="d.deviceId"
              :value="d.deviceId"
              :disabled="d.activation == 0"
            >
              {{ d.deviceId }}({{ d.activation === 0 ? '未激活' : '已激活' }})</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="IMEI" v-if="deviceType !== 3">
          <a-select
            show-search
            allowClear
            placeholder="请输入IMEI"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleImeiSearch"
            @change="handleImeiChange"
            :loading="loading"
            v-model="form.imei"
          >
            <a-select-option v-for="d in deviceArray" :key="d.imei" :value="d.imei"> {{ d.imei }} </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="绑定人员" prop="name" v-if="deviceType === 2 || deviceType === 3">
          <!-- identityCard -->
          <a-select
            show-search
            placeholder="请输入姓名查找"
            v-model="form.name"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            @search="handleEmployeeSearch"
            @change="handleEmployeeChange"
            :loading="loading"
          >
            <a-select-option v-for="d in employeeArray" :key="d.id" :value="d.id">
              {{ d.name }}-{{ d.identityCard }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="身份证号" v-if="deviceType === 2 || deviceType === 3">
          <!-- <a-input v-model="form.idCardNo" :disabled="true"></a-input> -->
          <a-select
            show-search
            placeholder="请输入身份证号码查找"
            v-model="form.idCardNo"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            @search="handleEmployeeIdCardSearch"
            @change="handleEmployeeChange"
            :loading="loading"
          >
            <a-select-option v-for="d in employeeArray" :key="d.id" :value="d.id">
              {{ d.name }}-{{ d.identityCard }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="车辆自编号" prop="vehicleNo" v-if="deviceType === 1">
          <a-select
            show-search
            allowClear
            placeholder="请输入车辆自编号"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleVehicleNoSearch"
            @change="handleVehicleNoChange"
            :loading="loading"
            v-model="form.vehicleNo"
          >
            <a-select-option v-for="d in vehicleNoArray" :key="d.vehicleNo" :value="d.vehicleNo">
              {{ d.vehicleNo }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="车辆号" v-if="deviceType === 1">
          <a-select
            show-search
            allowClear
            placeholder="请输入车辆车牌号"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleLicensePlateNumSearch"
            @change="handleLicensePlateNumChange"
            :loading="loading"
            v-model="form.plateNo"
          >
            <a-select-option v-for="d in vehicleNoArray" :key="d.licensePlateNum" :value="d.licensePlateNum">
              {{ d.licensePlateNum }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="deviceId" label="中控账号" v-if="deviceType === 3">
          <a-input v-model="form.deviceId" placeholder="请输入中控账号" allowClear></a-input>
        </a-form-model-item>
        <div class="bottom-control">
          <a-space>
            <a-button type="primary" @click="submitForm"> 确认绑定 </a-button>
          </a-space>
        </div>
      </a-form-model>
    </div>
  </vxe-modal>
</template>

<script>
import { bindDeviceEmployee, checkUserEmployee } from '@/api/jt808/deviceEmployee'
import { bindDeviceVehicle } from '@/api/jt808/deviceVehicle'
import { searchDevice } from '@/api/jt808/deviceInfo'
import { listEmployeeAccount } from '@/api/iot/employeeAccount'
import debounce from 'lodash/debounce'
import { listVehicleAccount } from '@/api/iot/vehicleAccount'
export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    const that = this
    this.handleEmployeeSearch = debounce(this.handleEmployeeSearch, 500)
    this.handleVehicleNoSearch = debounce(this.handleVehicleNoSearch, 500)
    this.handleLicensePlateNumSearch = debounce(this.handleLicensePlateNumSearch, 500)
    this.handleDeviceSearch = debounce(this.handleDeviceSearch, 500)
    this.handleImeiSearch = debounce(this.handleImeiSearch, 500)
    this.handleEmployeeIdCardSearch = debounce(this.handleEmployeeIdCardSearch, 500)

    return {
      loading: false,
      deviceType: 1,
      // 表单参数
      form: {
        deviceId: undefined,
        employeeId: null,
        name: undefined,
        idCardNo: undefined,
        plateNo: undefined,
        vehicleNo: undefined,
        jobNumber: undefined,
        status: 1
      },
      rules: {
        name: [
          {
            required: true,
            message: '绑定人员不能为空',
            trigger: 'blur'
          }
        ],
        deviceId: [
          {
            required: true,
            // message: '设备编号不能为空',
            trigger: 'blur',
            validator: async function (rule, value, callback) {
              if (!value) {
                callback(new Error(that.deviceType === 3 ? '中控账号不能为空' : '设备编号不能为空'))
                return
              }
              if (that.deviceType === 3) {
                const checkResult = await checkUserEmployee({
                  userName: that.form.deviceId
                })
                if (!checkResult.data) {
                  // this.$message.warn('账号输入错误，请检查后重新输入', 3)
                  callback(new Error('账号输入错误，请检查后重新输入'))
                  return
                }
              }
              callback()
            }
          }
        ],
        vehicleNo: [{ required: true, message: '车辆自编号不能为空', trigger: 'blur' }]
      },
      employeeArray: [],
      vehicleNoArray: [],
      deviceArray: [],
      deviceBindVisible: false,
      title: '',
      vehicleFunctionOptions: []
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {
    deviceType(newVal, oldVal) {
      this.deviceArray = []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      await this.getDicts('iot_vehicleAccount_function').then((response) => {
        this.vehicleFunctionOptions = response.data
      })
      await this.getDicts('iot_vehicleAccount_category').then((response) => {
        this.vehicleCategoryOptions = response.data
      })
    },
    handleAdd(deviceType) {
      this.reset()
      this.deviceBindVisible = true
      this.deviceType = deviceType
      const titleMap = {
        1: '车辆设备绑定',
        2: '人员设备绑定',
        3: '小程序设备绑定'
      }
      this.title = titleMap[deviceType]
    },
    handleCloseDeviceBind() {
      this.deviceBindVisible = false
    },
    handleEmployeeSearch(value) {
      console.log('handleEmployeeSearch', value)
      const queryParam = {
        name: value,
        pageNum: 1,
        pageSize: 10
      }
      this.loading = true
      listEmployeeAccount(queryParam).then((response) => {
        this.employeeArray = response.rows
        this.loading = false
        this.$forceUpdate()
      })
    },
    handleEmployeeIdCardSearch(value) {
      console.log('handleEmployeeIdCardSearch', value)
      const queryParam = {
        identityCard: value,
        pageNum: 1,
        pageSize: 10
      }
      this.loading = true
      listEmployeeAccount(queryParam).then((response) => {
        this.employeeArray = response.rows
        this.loading = false
        this.$forceUpdate()
      })
    },

    handleEmployeeChange(value) {
      console.log('handleEmployeeChange')
      const record = this.employeeArray.find((p) => p.id === value)
      this.form.idCardNo = record.identityCard
      this.form.name = record.name
      this.form.jobNumber = record.jobNumber
      this.form.deptId = record.deptId
      this.form.deptName = record.deptName
      this.form.phone = record.phone
      this.rank = record.rank
      this.sex = record.sex
    },
    handleVehicleNoSearch(value, fn) {
      console.log('handleVehicleNoSearch', value)
      let queryParam = {
        bindQuery: 'unbind',
        vehicleNo: value
      }
      if (value && !value.trim()) {
        queryParam = {
          bindQuery: 'unbind',
          pageNum: 1,
          pageSize: 10
        }
      }
      this.loading = true
      listVehicleAccount(queryParam).then((response) => {
        this.vehicleNoArray = response.rows
        this.loading = false
        this.$forceUpdate()
        fn && fn(value)
      })
    },
    handleLicensePlateNumSearch(value) {
      console.log('handleLicensePlateNumSearch', value)
      let queryParam = {
        bindQuery: 'unbind',
        licensePlateNum: value
      }
      if (value && !value.trim()) {
        queryParam = {
          bindQuery: 'unbind',
          pageNum: 1,
          pageSize: 10
        }
      }
      this.loading = true
      listVehicleAccount(queryParam).then((response) => {
        this.vehicleNoArray = response.rows
        this.loading = false
        this.$forceUpdate()
      })
    },
    handleVehicleNoChange(value) {
      const val = value === undefined ? '' : value
      this.form.vehicleNo = val
      if (val === '') {
        this.form.licensePlateNum = ''
      }
      // 设置车牌号
      const tmpArray = this.vehicleNoArray.filter((p) => p.vehicleNo === val)
      if (tmpArray.length > 0) {
        this.form.plateNo = tmpArray[0].licensePlateNum
        this.form.useDeptId = tmpArray[0].deptId
        this.form.useDeptName = tmpArray[0].deptName
        this.form.deptId = tmpArray[0].belongingToDept
        this.form.deptName = tmpArray[0].belongingToDeptName
        this.form.vehicleFunction = this.selectDictLabel(this.vehicleFunctionOptions, tmpArray[0].vehicleFunction)
        this.form.vehiclePhoto = tmpArray[0].vehiclePhoto
        this.form.vehicleCategory = this.selectDictLabel(this.vehicleCategoryOptions, tmpArray[0].vehicleCategory)
        this.$forceUpdate()
      }
    },
    handleLicensePlateNumChange(value) {
      const val = value === undefined ? '' : value
      this.form.plateNo = val
      if (val === '') {
        this.form.plateNo = ''
      }
      // 设置车辆自编号
      const tmpArray = this.vehicleNoArray.filter((p) => p.licensePlateNum === val)
      if (tmpArray.length > 0) {
        this.form.vehicleNo = tmpArray[0].vehicleNo
        this.form.deptId = tmpArray[0].deptId
        this.form.vehiclePhoto = tmpArray[0].vehiclePhoto
        this.form.vehicleCategory = tmpArray[0].vehicleCategory
        this.$forceUpdate()
      }
    },
    handleDeviceSearch(value, fn) {
      console.log('handleDeviceSearch', value)
      let queryParam = {
        deviceType: this.deviceType,
        deviceId: value,
        bindQuery: 'unbind',
        imei: this.form.imei
      }
      if (value && !value.trim()) {
        queryParam = {
          deviceType: this.deviceType,
          bindQuery: 'unbind',
          pageNum: 1,
          pageSize: 10
        }
      }
      this.loading = true
      searchDevice(queryParam).then((response) => {
        this.deviceArray = response.data
        this.loading = false
        this.$forceUpdate()
        fn && fn(value)
      })
    },
    handleImeiSearch(value, fn) {
      console.log('handleImeiSearch', value)
      let queryParam = {
        deviceType: this.deviceType,
        deviceId: this.form.deviceId,
        bindQuery: 'unbind',
        imei: value
      }
      if (value && !value.trim()) {
        queryParam = {
          deviceType: this.deviceType,
          bindQuery: 'unbind',
          pageNum: 1,
          pageSize: 10
        }
      }
      this.loading = true
      searchDevice(queryParam).then((response) => {
        this.deviceArray = response.data
        this.loading = false
        this.$forceUpdate()
        fn && fn(value)
      })
    },
    handleDeviceChange(val) {
      const record = this.deviceArray.find((p) => p.deviceId === val)
      if (record) {
        this.form.imei = record.imei
      }
    },
    handleImeiChange(val) {
      const record = this.deviceArray.find((p) => p.imei === val)
      if (record) {
        this.form.deviceId = record.deviceId
      }
    },

    // 表单重置
    reset() {
      this.form = {
        deviceId: undefined,
        name: undefined,
        idCardNo: undefined,
        plateNo: undefined,
        vehicleNo: undefined,
        status: 1
      }
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
          } else {
            if (this.deviceType === 1) {
              delete this.form.idCardNo
              delete this.form.name
              delete this.form.imei
              bindDeviceVehicle(this.form).then((response) => {
                if (response.data) {
                  this.$message.success('绑定成功', 3)
                  this.deviceBindVisible = false
                  this.$emit('ok')
                } else {
                  this.$message.warn(response.msg, 3)
                }
              })
            } else {
              delete this.form.plateNo
              delete this.form.vehicleNo
              delete this.form.imei
              const form = JSON.parse(JSON.stringify(this.form))
              await this.getDicts('iot_account_rank').then((response) => {
                this.rankOptions = response.data
              })
              await this.getDicts('sys_user_sex').then((response) => {
                this.sexOptions = response.data
              })
              form.position = this.selectDictLabel(this.rankOptions, this.rank)
              form.sex = this.selectDictLabel(this.sexOptions, this.sex)

              bindDeviceEmployee({
                deviceType: this.deviceType === 3 ? this.deviceType : '',
                ...form
              }).then((response) => {
                if (response.data) {
                  this.$message.success('绑定成功', 3)
                  this.deviceBindVisible = false
                  this.$emit('ok')
                } else {
                  this.$message.warn(response.msg, 3)
                }
              })
            }
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less">
.device-bind-container {
  background-color: @default-bg-color;
  width: 35%;
  min-width: 400px;
  margin-left: 50%;
  transform: translate(-50%);
  .ant-form {
    padding: 20px;
  }
  .ant-form-item {
    display: flex;
    margin-bottom: 10px;
    .ant-form-item-label {
      flex: 0 0 90px;
    }
    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
  .bottom-control {
    border-top: 0;
    text-align: center;
  }
  .ant-select-license {
    margin-top: 15px;
  }
  h4 {
    text-align: center;
    padding-top: 20px;
    margin-bottom: 0;
  }
}
</style>
